import React from 'react';

export const SaPa = {
    "name": "SaPa",
    "link": "sapa",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50379787808_43c88c8c5f_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379787808_bc929c2384_o.jpg",
    "caption": "The true countryside experience",

    "description": "SaPa is an oasis outside the bustling cities of the Vietnam. It is a small hill station town in Northern Vietnam near the border with China. It’s best known for the great landscape, surrounded by terraced rice fields that cover the Muong Hoa Valley. The town is gorgeously picturesque with buildings inspired by French architecture, local tribes selling handmade goods on the streets, and delicious local food.",

    "nrDays": <p>
        While it’s true that the length of your trip depends on the mode of transport you’d like to take, we are of the opinion that you will
        need at least <b>two full days</b> to get a chance to embrace the beauty of this amazing rural area.
              </p>,

    "whenVisit": <p>
        Thanks to its high altitude, SaPa is an ideal location for summer retreat during the overheated summer time and the
        scarcely populated town also has pretty fresh air. In terms of tourism the best time to visit SaPa is <b>from end-February
        to May</b> and <b>from mid-September to early December</b>.
        <br/><br/>
        In April and May, the locals grow rice on terraced fields.
        In September and October, when Autumn ends, travelers can feel prosperity all over the area as the rice in all terraced fields is ripe and displays a yellow color.
        </p>,

    "mobility": <p>
            SaPa is one of the most popular trekking areas in Vietnam, so get ready to make most of your way on foot. Inside the village it is
            relatively easy to get around on foot since everything is close. However, motorcycles, cars and buses increasingly clog the
            streets.
            <br/><br/>
            It's very common to visit the villages nearby and other spots such as <b>Cat Cat</b>, <b>Sinh Cai</b> and <b>Lao Chai</b> on foot and
            then by car or motorbike on the way back. The walkings are not too complicated, but you will need to be in an acceptable level of fitness and
            mobility in order to do it.
            <br/><br/>
            To get to SaPa the train is the preferred means of transportation from Hanoi. However, with a duration of 8 hours and 20 minutes,
            those night trains only take you to Lao Cai station and you will need to make the transfer to Sapa. Therefore, you will need to take a Lao Cai – Sapa bus
            route at 2.5$/person for the distance of 38km. You can also buy tickets directly at the station or book in advance.
            <br/><br/>
            Recently, a new road between this area of Lao Cai and Hanoi has finished construction, and now there are regular buses that are taking this route.
            They take around 5 to 6 hours to reach the destination, and drop you at SaPa without any extra stop.
            You can also take a private car from Hanoi with an English-speaker guide that are a little more expensive (around 350$ by group).
                </p>,

    "safety": <p>
        SaPa is super safe for tourists in terms of petty crime. The biggest issue that you will find here are the <b>motorcycles accidents</b> and the accidents that may happen while trekking the countryside, such as <b>falling and getting hurt</b>.
            </p>,

    "itinerary": {
        "description": <p>
            As described before, we recommend 2 full days to see the area. SaPa is a gem full of surprises and amazing landscapes.
            If you are here at a Sunday, take some time in the morning to visit the <b>Bac Ha Market</b>. We will not put this on the suggested
            itinerary but don't forget to take this into account.
        </p>,

        "days": [
            {
                "description":
                    <p>
                    For the first day, we recommend to start with a small visit to the <b>village of SaPa</b>. During the rest of the
                    day take your time to embrace the nature and do some treks in the area. We highly recommend you do the trail by the
                    <b>Muong Hoa River</b> until the end of the valley to see the amazing views of the largest farmland for rice growing.
                    <br/><br/>
                    Continue on your way here until the <b>Fansapan Mountain</b>, and if you are interested, take the cable car to reach the top of the mountain.
                    At night take your time to relax at a good restaurant and try some local cuisine.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50514531736_0a18e30704_o.jpg",
                "caption": "Muong Hoa Valley viewpoint."
            },
            {
                "description": <p>
                    Nested in a beautiful valley about 3km from SaPa, the village of <b>Cat Cat</b> is home to some ethnic minorities, like the black H’mong, and is a good place to visit in the morning.
                    The road to this village is very pleasant for trekking enthusiasts since it takes only 20 to 30 minutes to walk from the center of SaPa.
                    <br/><br/>
                    On the afternoon, go visit the <b>Ta Van Village</b>, that is located around 10km from SaPa.
                    You can do it on foot if you think you are able to. if you feel tired you can return on a motorbike.
                </p>,
                "image": "https://live.staticflickr.com/65535/50514695067_215a2eba30_o.jpg",
                "caption": "Cat Cat village."
            }
        ]
    },

    "highlights": [
        {
            "name": "Fansipan Mountain",
            "description": <p>
                If you are interested in hiking Fansipan, consider doing it with <a href="http://sapaoriginaltrek.com/" target="_blank" rel="noopener noreferrer">Sapa Original Trek</a>. You can also take the cable car here to climb to the very top.
                The view there is absolutely gorgeous, it is an effort that is worth it.
            </p>,
            "image": "https://live.staticflickr.com/65535/50513818243_dc3e1b758b_o.jpg"
        },
        {
            "name": "Ta Van village",
            "description": <p>
            Ta Van village is home to many Vietnam ethnic minorities and a place where they live in harmony since a long time ago.
            You will visit the biggest valley in Vietnam with an awesome landscape of the golden rice terrace fields.
            </p>,
            "image": "https://live.staticflickr.com/65535/50513818213_615f1f3fdd_o.jpg"
        },
        {
            "name": "Muong Hoa Valley",
            "description": <p>
                Muong Hoa is known as the largest farmland for rice growing in SaPa, and is one of the most famous places due to the breathtaking scenery.
                It is a little far from town, about 14 kilometers from Sapa. To approach Muong Hoa Valley, we recommend the trek along Muong Hoa River.
            </p>,
            "image": "https://live.staticflickr.com/65535/50513818178_f1774e9ec6_o.jpg"
        },
        {
            "name": "Cat Cat village",
            "description": <p>
                Cat Cat is an old village of ethnic minorities which attracts tourists from all over the world for its distinctive customs
                and practices. After getting close to the foot of the mountain by motorbike or by car, you can walk to Cat Cat Village.
                Its nearby hills provide an opportunity to see farms and an amazing view to the surrounding landscape.
            </p>,
            "image": "https://live.staticflickr.com/65535/50514531751_839f017773_o.jpg"
        }
    ],

    "food": {
        "description": <p>

            </p>,
        "restaurants": [
            {
                "name": "Anise Sapa Restaurant- Nhà Hàng Anise Sapa",
                "description": "Nice staff and tasty food that is served in style. Everything delicious.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50514695272_51d68a9a78_o.jpg"
            },
            {
                "name": "Aira Sapa Restaurant & Bar",
                "description": "Lovely presentation of local tribal dishes in a lovely setting. Comfortable accommodation and friendly staff.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50514531861_54833207e7_o.jpg"
            },
            {
                "name": "Little Vietnam Restaurant",
                "description": " The Papaya salad, fresh spring rolls, and Chicken Coconut Curry are delicious, and the staff is very friendly.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50513818288_756dd8a5e2_o.jpg"
            },
            {
                "name": "Good Morning View Restaurant",
                "description": "This restaurant is a gem, make sure you don’t leave Sapa without visiting it. The food is delicious.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50514695207_850fe6c07f_o.jpg"
            },
            {
                "name": "Moment Romantic Restaurant",
                "description": "Great little restaurant in Sapa. Very helpful staff and lovely food. Try the local hot wine.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50514695167_3caafd4149_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "SaPa is getting more tourists every year and the hotels available in the area are also having a substantial growth. You will find very different options, from local homestays to luxurious hotels.",
        "budget": [
            {
                "name": "Fansipan Terrace Cafe and Homestay",
                "description": "Set 3.1 km from Fansipan Legend Cable Car Station, Fansipan Terrace Cafe and Homestay offers a restaurant and a bar.",
                "image": "https://live.staticflickr.com/65535/50514695347_f36063fc36_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Icon 36 Hotel (Formerly Sapa Summit Hotel)",
                "description": "Close to the city centre and with a good breakfast option, this hotel is a comfortable choice for your stay.",
                "image": "https://live.staticflickr.com/65535/50514531906_caf5eaecd7_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Hotel de la Coupole - MGallery",
                "description": "A very luxurious option with classic and French architecture.",
                "image": "https://live.staticflickr.com/65535/50514531876_2e73a3d228_o.jpg",
                "link": "/"
            }
        ]
    }
}